import axios from "axios";

const API_ENDPOINT =
  typeof window !== "undefined" &&
  (window.location.origin === "http://localhost:8000"
    ? "https://sheamoisture-web-us-staging.netlify.app/.netlify/functions"
    : window?.location?.origin + "/.netlify/functions");

export const getProductsByID = async (productIds: any, getAll: boolean) => {
  if (!productIds) {
    return;
  }
  try {
    const response = await axios({
      method: "post",
      url: `${API_ENDPOINT}/getSanityProductsById`,
      data: { productIds, getAll }
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};
