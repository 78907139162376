import React, { useContext } from "react";
import { LocalizedContext } from "../../../../services/LocalizedContextService";
import SanityImage from "gatsby-plugin-sanity-image";
import "./styles.scss";
import { IMAGE_SIZES } from "../../../../constants";

const OrderProduct = ({
  key,
  productName,
  productId,
  quantity,
  productItemId,
  basePrice,
  priceAfterItemDiscount,
  priceAfterOrderDiscount,
  productData
}: {
  key: number;
  productName: string;
  productId: string;
  quantity: string;
  productItemId: string;
  basePrice: number;
  priceAfterItemDiscount: number;
  priceAfterOrderDiscount: number;
  productData?: any;
}) => {
  const { sanitySiteSettings } = useContext(LocalizedContext);

  const orderImage =
    productData && productData.images && productData.images.length
      ? productData.images[0]
      : sanitySiteSettings._rawProductFallbackImage;

  const linkToProduct = productData && !productData.isHiddenOnWeb ? productData.slug?.current : "";
  const quantityNumber = Number(quantity);
  const discountedPrice =
    quantityNumber && quantityNumber !== 0 ? priceAfterItemDiscount / quantityNumber : priceAfterItemDiscount;

  return (
    <div className="order-product__wrapper" key={`product-${key}`}>
      <p className="order-product__name">
        {linkToProduct ? (
          <a className="order-product__name-link" href={linkToProduct}>
            {productName}
          </a>
        ) : (
          productName
        )}
      </p>
      <div className="order-product__details">
        <div className="order-product__image">
          <SanityImage
            {...orderImage}
            width={90}
            height={90}
            htmlWidth={90}
            htmlHeight={90}
            className="img-fluid"
            sizes={IMAGE_SIZES.TINY_SQUARE}
            alt={orderImage?.alt ? orderImage?.alt : productName}
          />
        </div>
        <div className="order-product__info-block order-product__row">
          <div className="order-product__priceInfo">
            <span>Each</span>
            {basePrice !== priceAfterItemDiscount ? (
              <div className="order-product__price ">
                <p className="order-product__originalPrice-strike">${basePrice.toFixed(2)}</p>
                <p className="order-product__discountPrice">${discountedPrice.toFixed(2)}</p>
              </div>
            ) : (
              <p className="order-product__originalPrice">${basePrice.toFixed(2)}</p>
            )}
          </div>
          <div className="order-product__qunatity-wrapper">
            <span>Quantity</span>
            <p>{quantity}</p>
          </div>
          <div className="order-product__priceInfo">
            <span>Total</span>
            <p className="order-product__totalPrice ">
              ${priceAfterItemDiscount !== basePrice ? priceAfterItemDiscount.toFixed(2) : basePrice.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderProduct;
