import React, { useState, useEffect } from "react";
import { getProductsByID } from "../../../services/ProductById";
import { ImageInterface } from "../../../types/SanityTypes";
import OrderBlock from "./OrderBlock";
import { getUserOrders } from "../../../services/salesforce/Account/user";
import { getAuthUser } from "../../../services/salesforce/Auth";
import LoaderIcon from "../../../images/icons/loader";

import "./styles.scss";

export type AddressInterface = {
  address1: string;
  city: string;
  countryCode: string;
  fullName: string;
  phone: string;
  postalCode: string;
  stateCode: string;
};

export type ProductItemInterface = {
  productName: string;
  productId: string;
  quantity: string;
  productItemId: string;
  basePrice: number;
  priceAfterItemDiscount: number;
  priceAfterOrderDiscount: number;
};

export type ProductPaymentInstrumentInterface = {
  paymentCard: {
    cardType: string;
    expirationMonth: number;
    expirationYear: number;
    maskedNumber: string;
  };
  paymentInstrumentId: string;
  paymentMethodId: string;
  paymentTransaction: {
    amount: number;
    transactionId: string;
  };
};

export type ProductsDataInterface = {
  _id: string;
  images: ImageInterface[];
  name: string;
  isHiddenOnWeb: boolean;
  slag: {
    current: string;
  };
};

export type ShippmentInterface = {
  shipmentId: string;
  shippingAddress: AddressInterface;
  shippingMethod: {
    id: string;
    name?: string;
    description?: string;
  };
  shipmentTotal: number;
  shippingStatus: string;
};

export type OrderReceiptDataInterface = {
  orderNo: string;
  creationDate: string;
  currency: string;
  customerLocale?: string;
  customerInfo: {
    email: string;
  };
  billingAddress: AddressInterface;
  shipments: ShippmentInterface[];
  status: string;
  orderTotal: number;
  totalNumberOfItems?: number;
  productItems: ProductItemInterface[];
  productSubTotal: number;
  productTotal: number;
  shippingTotal: number;
  taxTotal: number;
  paymentInstruments: ProductPaymentInstrumentInterface[];
  productData: ProductsDataInterface[];
  orderDiscount?: number;
};

export interface OrderDataInterface extends OrderReceiptDataInterface {
  orderPriceAdjustments?: {
    basePrice: number;
    grossPrice: number;
    netPrice: number;
    tax: number;
    appliedDiscount: {
      amount: number;
    };
  }[];
}

const OrderHistory = () => {
  const user = getAuthUser();

  const [productData, setProductData] = useState<any>();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [customerOrders, setCustomerOrders] = useState<any>({});
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const userOrdersPromise = getUserOrders(user.rcid);
    userOrdersPromise
      .then(res => {
        res && res.data && setCustomerOrders(res.data);
        setIsLoadingData(false);
      })
      .catch(err => {
        console.log(err);
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    if (customerOrders) {
      fetchData();
    }
  }, [isLoadingData]);

  //function to get array of producIDs
  const getProductIDs = (orderData: any) => {
    if (!(orderData && orderData.length)) return;
    const arrayOfID: string[] = [];
    if (orderData && orderData.length) {
      orderData.forEach((order: any) => {
        if (order.productItems && order.productItems.length) {
          order.productItems.forEach((item: any) => {
            item.productId && arrayOfID.push(item.productId);
          });
        }
      });
    }

    return arrayOfID;
  };

  async function fetchData() {
    const ids = getProductIDs(customerOrders.data);
    try {
      const response = await getProductsByID(ids, true);
      setProductData(response?.data);
    } catch (err) {
      console.log(err);
    }
  }

  //function to filter needed products
  const getOrderProductsData = (order: any, productData: any) => {
    if (productData && productData.length && order && order.productItems && order.productItems.length) {
      const arrayOfID: string[] = [];
      order.productItems.forEach((item: any) => arrayOfID.push(item.productId));
      return productData.filter((item: ProductsDataInterface) => arrayOfID.includes(item._id));
    }
    return [];
  };

  return (
    <div className="order-history">
      {isError ? (
        <p>Error fetching user orders data.</p>
      ) : isLoadingData ? (
        <LoaderIcon width={100} height={100} className="loaderIcon" />
      ) : (
        customerOrders?.data &&
        customerOrders.data?.length && (
          <div className="order-history__list">
            {customerOrders.data.map((order: any, index: number) => (
              <>
                <div key={index} className="order-history__list-item">
                  <OrderBlock {...order} productData={getOrderProductsData(order, productData)} />
                </div>
              </>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default OrderHistory;
