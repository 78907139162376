import React, { FunctionComponent } from "react";
import { OrderReceiptDataInterface, ProductsDataInterface } from "../index";
import OrderProduct from "../OrderProduct";
import "./styles.scss";

const OrderReceipt: FunctionComponent<OrderReceiptDataInterface> = orderData => {
  const {
    orderNo,
    creationDate,
    currency,
    customerLocale = "en-US",
    customerInfo,
    billingAddress,
    shipments,
    status,
    orderTotal,
    totalNumberOfItems,
    productItems,
    paymentInstruments,
    productSubTotal,
    productTotal,
    shippingTotal,
    taxTotal,
    orderDiscount,
    productData
  } = orderData;

  const formatMoneyAmount = (amount: number, customerLocale: string, currency: string) => {
    return new Intl.NumberFormat(customerLocale.replace("_", "-"), {
      style: "currency",
      currency: currency
    }).format(amount);
  };

  const orderDate = creationDate ? new Date(creationDate).toLocaleDateString() : "";

  const orderTotalFormatted = formatMoneyAmount(orderTotal, customerLocale, currency);
  const productSubTotalFormatted = formatMoneyAmount(productSubTotal, customerLocale, currency);
  const shippingTotalFormatted = formatMoneyAmount(shippingTotal, customerLocale, currency);
  const orderDiscountFormatted = orderDiscount ? formatMoneyAmount(orderDiscount, customerLocale, currency) : 0;
  const taxTotalFormatted = formatMoneyAmount(taxTotal, customerLocale, currency);

  const getProductData = (productID: string, productsData: ProductsDataInterface[]) => {
    if (productID && productsData && productsData.length) {
      return productsData.find(element => element._id == productID);
    }
    return {};
  };

  return (
    <div className="order-receipt">
      <div className="order-receipt__customer-info">
        <div className="order-receipt__status">
          <p>
            Date Ordered: <span className="highlighted">{orderDate}</span>
          </p>
          <p>
            Order Status: <span className="highlighted">{status}</span>
          </p>
        </div>

        <div>
          {shipments &&
            shipments.length &&
            shipments.map((shippment: any, shippmentIndex: number) => (
              <div className="shipping-info" key={`shippment-${shippmentIndex}`}>
                <div className="shipping-address spt-16 spb-16">
                  <div className="text__size-h4">Shipping Address</div>
                  <div>{shippment.shippingAddress.fullName}</div>
                  <div>{`${shippment.shippingAddress.address1}`}</div>
                  <div>{`${shippment.shippingAddress.city}, ${shippment.shippingAddress.stateCode} ${shippment.shippingAddress.postalCode}`}</div>
                  <div>{`${shippment.shippingAddress.phone}`}</div>
                </div>
                <div className="shipping-method spt-16 spb-16">
                  <div className="text__size-h4">Shipping Method</div>
                  <div className="shippment-method-info">
                    <div>{`${shippment.shippingMethod.name ? shippment.shippingMethod.name + " " : ""}${
                      shippment.shippingMethod.description ? "(" + shippment.shippingMethod.description + ")" : ""
                    }`}</div>
                    <div className="shippment-total">
                      {formatMoneyAmount(shippment.shipmentTotal, customerLocale, currency)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="billingAddress spt-16 spb-16">
          <div className="text__size-h4">Billing Address</div>
          <div>{billingAddress.fullName}</div>
          <div>{`${billingAddress.address1}`}</div>
          <div>{`${billingAddress.city}, ${billingAddress.stateCode} ${billingAddress.postalCode}`}</div>
          <div>{`${billingAddress.phone}`}</div>
          <div>{customerInfo.email}</div>
        </div>
        <div className="payment spt-16 spb-16">
          <div className="text__size-h4">Payment</div>
          {paymentInstruments.map((payment: any, indexPay: number) => (
            <div className="payment-block" key={`payment-${indexPay}`}>
              <div>
                {payment.paymentCard && (
                  <p>
                    {payment.paymentCard.cardType} {payment.paymentCard.maskedNumber}
                  </p>
                )}
                {payment.paymentCard && (
                  <p>
                    Valid until {payment.paymentCard.expirationMonth}/{payment.paymentCard.expirationYear}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="order-products">
          <div className="order-products-header">
            <p className="text__size-h4">{totalNumberOfItems} Items</p>
            <p className="text__size-h4">{orderTotalFormatted}</p>
          </div>
          <div className="order-products__list">
            {productItems &&
              productItems.map((item: any, index: number) => (
                <OrderProduct key={index} {...item} productData={getProductData(item.productId, productData)} />
              ))}
          </div>
        </div>
        <div className="order-total-summary">
          <div className="order-total-summary__subtotal order-receipt__row highlighted">
            <p>Subtotal</p>
            <p>{productSubTotalFormatted}</p>
          </div>
          {orderDiscount ? (
            <div className="order-total-summary__discount order-receipt__row highlighted">
              <p>Order Discount</p>
              <p>{orderDiscountFormatted}</p>
            </div>
          ) : (
            ""
          )}
          <div className="order-total-summary__shipping order-receipt__row highlighted">
            <p>Shipping</p>
            <p>{shippingTotalFormatted}</p>
          </div>
          <div className="order-total-summary__stax order-receipt__row highlighted">
            <p>Tax</p>
            <p>{taxTotalFormatted}</p>
          </div>
          <div className="order-total-summary__total order-receipt__row highlighted">
            <p>Total</p>
            <p>{orderTotalFormatted}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderReceipt;
