import React, { FunctionComponent, useContext, useState } from "react";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import SanityImage from "gatsby-plugin-sanity-image";
import { LocalizedContext } from "../../../../services/LocalizedContextService";
import { OrderDataInterface } from "../index";
import OrderReceipt from "../OrderReceipt";
import "./styles.scss";
import { IMAGE_SIZES } from "../../../../constants";

const OrderBlock: FunctionComponent<OrderDataInterface> = orderData => {
  const {
    orderNo,
    creationDate,
    currency,
    customerLocale = "en-US",
    shipments,
    status,
    orderTotal,
    productItems,
    orderPriceAdjustments,
    productData
  } = orderData;

  const { sanitySiteSettings } = useContext(LocalizedContext);

  const formatMoneyAmount = (amount: number, customerLocale: string, currency: string) => {
    return new Intl.NumberFormat(customerLocale.replace("_", "-"), {
      style: "currency",
      currency: currency
    }).format(+amount);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  const orderDate = creationDate ? new Date(creationDate).toLocaleDateString() : "";
  const orderShippmentName =
    shipments.length && shipments[0].shippingAddress ? shipments[0].shippingAddress.fullName : "";

  const getOrderQuantity = (productItems: any[]) => {
    return productItems.length ? productItems.reduce((accumulator, item) => accumulator + item.quantity, 0) : 0;
  };

  const getOrderDiscount = (orderPriceAdjustments: any[]) => {
    return orderPriceAdjustments.reduce((accumulator, item) => accumulator + item.appliedDiscount.amount, 0);
  };

  const itemQuantity = getOrderQuantity(productItems);
  const orderDiscount =
    orderPriceAdjustments && orderPriceAdjustments.length ? getOrderDiscount(orderPriceAdjustments) : 0;

  const orderTotalFormatted = formatMoneyAmount(orderTotal, customerLocale, currency);

  const [showDetails, setShowDetails] = useState(false);

  const handleDetails = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  const orderImage =
    productData && productData.length && productData[0].images && productData[0].images.length
      ? productData[0].images[0]
      : sanitySiteSettings._rawProductFallbackImage;

  return (
    <div className="order-history__order">
      <div className="order-history__order-title">
        <div className="text__size-h3">Order No. {orderNo}</div>
        <a
          href="#"
          className="order-history__view-order button button-nonary button-size-sm button-form-br-50"
          onClick={handleDetails}
        >
          {showDetails ? "Hide" : "View"}
        </a>
      </div>
      {!showDetails ? (
        <div className="order-history__order-short">
          <div className="order-history__order-body">
            {!isMobile ? (
              <div className="order-history__order-image">
                <SanityImage
                  {...orderImage}
                  width={90}
                  height={90}
                  htmlWidth={90}
                  htmlHeight={90}
                  className="img-fluid"
                  sizes={IMAGE_SIZES.TINY_SQUARE}
                  alt={orderImage?.alt ? orderImage?.alt : "product image"}
                />
              </div>
            ) : (
              ""
            )}
            <div className="order-history__order-info">
              <p>
                Date Ordered: <span className="highlighted">{orderDate}</span>
              </p>
              <p>
                Order Status: <span className="highlighted">{status}</span>
              </p>
              <p>
                Shipped to: <span className="highlighted">{orderShippmentName}</span>
              </p>
            </div>
          </div>
          <div className="order-history__order-footer">
            <div className="order-history__order-totlal-items">
              <p>Total items</p>
              <p className="highlighted">{itemQuantity}</p>
            </div>
            <div className="order-history__order-totlal-sum">
              <p>Total </p>
              <p className="highlighted">{orderTotalFormatted}</p>
            </div>
          </div>
        </div>
      ) : (
        <OrderReceipt {...orderData} orderDiscount={orderDiscount} />
      )}
    </div>
  );
};

export default OrderBlock;
